import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useSearch } from '@hooks'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isMobileProject } from '@utils'
import { getItem } from '@utils/storage'
import { Knife, Type } from '@utils/tool'

import { AppState } from '../store/slice/appSlice'
import {
  addCart,
  deleteCart,
  getCart,
  getCartParamsType,
  selectedCart,
  updateCart
} from './action'
import {
  AddCartParams,
  CartReturnType,
  CouponResult,
  DeleteParams,
  SelectedParams,
  UpdateParams
} from './types'
import { updateCouponInfo } from '../store/slice/appSlice'

/**
 * @description: 获取购物车列表数据（有分组）
 * @param {*} dep
 * @param {*} from 来自哪个位置，当前有详情页加购弹框和购物车页
 * @param {Object} getCartParams getCart 接口参数
 * @return {*}
 */
export const useShopCartGroupList = (
  dep = [],
  from?: 'drawer' | 'cart',
  getCartParams: getCartParamsType = {}
) => {
  const { shopGroupList, shopListTotalInfo, shopList } = useSelector(
    (state: any) => state.shopcart as typeof initialState
  )
  const appState: AppState = useSelector((state: any) => state.app)
  const dispatch = useDispatch()
  const noGroup = getCartParams?.show_group === '0'

  const { isLoading, isError, refetch } = useSearch(
    () => {
      const params: getCartParamsType = { show_group: '1', ...getCartParams }

      // 抽过奖且存在中间 coupon 把 coupon code 传给接口
      if (Cookies.get('lwf') === '1' && getItem('lwRes')?.availableCouponCode) {
        params.lottery_coupon = getItem('lwRes')?.availableCouponCode
      }

      // 当前加购 skuid 是否有值，获取本次加购的数据，应用于详情页加购弹框显示本次加购数据
      // const skuIds = appState.currentAddCartSkuIds || []
      // skuIds.length > 0 && from === 'drawer' && (params.show_sku_list = skuIds)

      return getCart(params).then((data) => {
        // 不显示分组的数据要单独处理，数据结构不一样
        if (noGroup) {
          dispatch(setList(data))
        } else {
          dispatch(setShopGroupList(data))
        }
        dispatch(setShopListTotalInfo(data))

        // 如果有subscribe_coupon ，则更新coupon数据
        const { subscribeCoupon, subscribeStatus } = data
        if (subscribeCoupon !== 'undefined') {
          dispatch(
            updateCouponInfo({
              subscribeCoupon,
              subscribeStatus
            })
          )
        }

        return data
      })
    },
    [appState.isLogin, ...dep],
    { key: 'shopCartGroupList', debounced: 400 }
  )

  const isEmpty = Type.isEmpty(
    noGroup ? shopList?.productList : shopGroupList?.productGroup
  )

  const shopListForGroup = React.useMemo(
    () => ({
      productList: (
        shopGroupList?.productGroup?.map((item) => item?.productList) || []
      ).flat()
    }),
    [shopGroupList]
  )

  // 头部购物车图标上面的商品数
  const totalNum = Number(shopListTotalInfo?.itemNumTotal) || 0

  const [currentCoupon] = Knife.map(shopListTotalInfo.addCouponList)

  return {
    shopList: noGroup ? shopList : shopListForGroup,
    shopListTotalInfo,
    shopGroupList,
    isEmpty,
    refetch,
    isLoading,
    isError,
    dispatch,
    totalNum,
    currentCoupon
  }
}

/**
 * @description: 获取购物车列表数据（无分组）
 * @param {*} dep
 * @param {*} from 来自哪个位置，当前有详情页加购弹框和购物车页
 * @param {Object} getCartParams getCart 接口参数
 * @return {*}
 */
export const useShopCartList = (
  dep = [],
  from?: 'drawer' | 'cart',
  getCartParams: getCartParamsType = {}
) => {
  const { shopList, shopListTotalInfo, ...rest } = useSelector(
    (state: any) => state.shopcart as typeof initialState
  )
  const appState: AppState = useSelector((state: any) => state.app)

  // appState.isLogin
  const { isLoading, isError, refetch } = useSearch(
    () => {
      const params: getCartParamsType = { ...getCartParams }

      // 抽过奖且存在中间 coupon 把 coupon code 传给接口
      if (Cookies.get('lwf') === '1' && getItem('lwRes')?.availableCouponCode) {
        params.lottery_coupon = getItem('lwRes')?.availableCouponCode
      }

      // 当前加购 skuid 是否有值，获取本次加购的数据，应用于详情页加购弹框显示本次加购数据
      const skuIds = appState.currentAddCartSkuIds || []
      isMobileProject &&
        skuIds.length > 0 &&
        from === 'drawer' &&
        (params.show_sku_list = skuIds)

      return getCart(params).then((data) => {
        dispatch(setList(data))
        dispatch(setShopListTotalInfo(data))
        return data
      })
    },
    [appState.isLogin, ...dep],
    { key: 'shopCartList', debounced: 400 }
  )

  const dispatch = useDispatch()

  const categoryData = Type.get(() => shopList.productList, Array)

  const isEmpty = Type.isEmpty(shopList?.productList)

  const totalNum = Number(shopListTotalInfo?.itemNumTotal) || 0

  const [currentCoupon] = Knife.map(shopListTotalInfo.addCouponList)

  return {
    currentCoupon,
    totalNum,
    isEmpty,
    refetch,
    categoryData,
    shopList,
    isLoading,
    isError,
    shopListTotalInfo,
    dispatch,
    ...rest
  }
}

type UserInfo = Type.DefineObject

const initialState = {
  shopGroupList: {} as any,
  shopList: [] as unknown as CartReturnType['blockList'][number],
  userInfo: {} as UserInfo,
  shopListTotalInfo: {} as unknown as Omit<CartReturnType, 'blockList'>,
  couponToekn: ''
}

export type CartStore = typeof initialState

export const cartSlice = createSlice({
  name: 'shopcart',
  initialState,
  reducers: {
    setShopGroupList: (state, action: PayloadAction<any>) => {
      state.shopGroupList = Type.get(() => action.payload.blockList[0], Object)
      state.shopGroupList?.productGroup?.forEach?.((group) => {
        group?.productList?.map((data) => {
          data.addTime = group.addTime
          if (data.finalPrice >= data.marketPrice) {
            data.formatMarketPrice = ''
          }
        })
      })
    },
    setList: (state, action: PayloadAction<Partial<CartReturnType>>) => {
      state.shopList = Type.get(() => action.payload.blockList[0], Object)
      state.shopList.productList?.forEach?.((data) => {
        if (data.finalPrice >= data.marketPrice) {
          data.formatMarketPrice = ''
        }
      })
    },
    setShopListTotalInfo: (state, action: PayloadAction<CartReturnType>) => {
      state.shopListTotalInfo = Knife.omit(action.payload, 'blockList')
    },
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload
    },
    setCouponToekn(state, action: PayloadAction<CouponResult>) {
      state.couponToekn = action.payload.token
    }
  }
})

//配合useDispatch
export const getShopList =
  (): any => (dispatch: ReturnType<typeof useDispatch>) => {
    getCart().then((data) => {
      dispatch(setList(data))
      dispatch(setShopListTotalInfo(data))
    })
  }

export const addGoods =
  (data: AddCartParams): any =>
  (dispatch: ReturnType<typeof useDispatch>) => {
    return addCart(data).then((data) => {
      dispatch(setList(data))
      dispatch(setShopListTotalInfo(data))
    })
  }

export const deleteGoods =
  (data: DeleteParams): any =>
  (dispatch: ReturnType<typeof useDispatch>) => {
    deleteCart(data).then((data) => {
      dispatch(setList(data))
      dispatch(setShopListTotalInfo(data))
    })
  }

export const updateGoods =
  (data: UpdateParams): any =>
  (dispatch: ReturnType<typeof useDispatch>) => {
    return updateCart(data).then((data) => {
      dispatch(setList(data))
      dispatch(setShopListTotalInfo(data))
    })
  }

export const selectedGoods =
  (data: SelectedParams): any =>
  (dispatch: ReturnType<typeof useDispatch>) => {
    selectedCart(data).then((data) => {
      dispatch(setList(data))
      dispatch(setShopListTotalInfo(data))
    })
  }

export const {
  setUserInfo,
  setList,
  setShopGroupList,
  setShopListTotalInfo,
  setCouponToekn
} = cartSlice.actions

export default cartSlice.reducer
