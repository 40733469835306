import { message } from 'antd'
import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse
} from 'axios'

import { Knife } from './tool'

const { camelCase, camelCaseToUnderline } = Knife

export const createRequest = (baseUrl: string, extraOption = {}) => {
  /**
   * 错误码
   * @mark 205 异常状态码 用于自定义错误信息
   */
  const RepErrorStatus = [400, 401, 402, 403, 404, 405, 500, 501, 502, 503]

  /**
   * @description: 错误也需要返回 response 给业务组件的 api
   * @return {*}
   */
  const ReqWhiteList = [
    '/turntable_lottery/turntableAward',
    '/account/thirdPartyLogin',
    '/account/linkEmail',
    '/shopcart/doChangeCoupon'
  ]

  //process.env.localIPAddress
  const http = axios.create({
    baseURL: baseUrl,
    timeout: 30 * 1000,
    ...extraOption
  })

  // 文件/头像相关的url
  const uploadFileUrl: any[] = ['articles/bulkUploadsImages']

  // http request 拦截器（设置头部信息）
  http.interceptors.request.use(
    async (config) => {
      // console.log('axios,config', config)
      if (config.method === 'post') {
        if (uploadFileUrl.indexOf(config.url?.substr(1)) > -1) {
          config.headers = {
            'Content-Type': 'multipart/form-data' // 设置跨域头部
          } as AxiosRequestHeaders
        }
      }
      //console.log('axios config', config, config.headers)
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  http.interceptors.response.use(
    (response: any) => {
      const res = response.data

      if (res && typeof res.code !== 'number') {
        res.code = +res.code
      }

      if (res.code !== 200) {
        res.message && response.config.showToast && message.error(res.message)
        console.error(
          `error url: ${response.config.url}  code: ${res.code}  message: ${res.message}`
        )
        return res
      }

      return res
    },
    (error) => {
      console.log('请求出错:\n' + error)
      return Promise.reject(error)
    }
  )

  return {
    get<T = any>(
      url: string,
      params: any = {},
      option: AxiosRequestConfig<any> = {}
    ): Promise<AxiosResponse<T> & BaseResult> {
      const reqUrl = url
      return http<any, BaseResult>({
        method: 'get',
        url,
        params: camelCaseToUnderline(params),
        ...option
      }).then((res) => {
        if (
          RepErrorStatus.includes(res.code) &&
          !ReqWhiteList.includes(reqUrl)
        ) {
          throw new Error(res.message)
        }

        if (res.code === 306) {
          setTimeout(() => {
            window.location.href = '/'
          }, 1000)
          throw new Error(res.message)
        }
        if (res.code === 307) {
          setTimeout(() => {
            window.location.href = '/checkout'
          }, 3000)
          throw new Error(res.message)
        }
        if (res.code === 406) {
          window.location.href = '/payment-waiting'
          throw new Error(res.message)
        }
        return camelCase(res) as any
      })
    },
    post<T = any>(
      url: string,
      params: any = {},
      option: AxiosRequestConfig<any> = {}
    ): Promise<AxiosResponse<T> & BaseResult> {
      const reqUrl = url
      //请求参数的key 小驼峰修改成下划线
      let data = camelCaseToUnderline(params)
      return http.post<any, BaseResult>(url, data, option).then((res) => {
        if (
          RepErrorStatus.includes(res.code) &&
          !ReqWhiteList.includes(reqUrl)
        ) {
          throw new Error(res.message)
        }
        if (res.code === 306) {
          setTimeout(() => {
            window.location.href = '/'
          }, 5000)
          throw new Error(res.message)
        }
        if (res.code === 307) {
          setTimeout(() => {
            window.location.href = '/checkout'
          }, 3000)
          throw new Error(res.message)
        }
        if (res.code === 406) {
          window.location.href = '/payment-waiting'
          throw new Error(res.message)
        }
        return camelCase(res) as any
      })
    }
  }
}

type BaseResult = { data: any; code: number; message: string }

/**
 * 公用请求方法,默认将后端返回的数据格式转换成驼峰用于前端处理
 * 将请求参数转换成下划线用于后台解析接收
 */
export const request = createRequest('/api')

export type Request = typeof request
