import { message, notification } from 'antd'

import { routesPath } from '@api/home/utils'
import { showCheckoutSubscriptionTip } from '@ui-pc/Subscription'
import {
  getAllSearchParams,
  getSearchParams,
  omitUrlSearch,
  setSearchParams
} from '@utils'
import { request } from '@utils/request'
import { Knife, Type } from '@utils/tool'

import { UserInfo } from './shippingAddress/types'
import {
  AddCartParams,
  CartReturnType,
  CouponResult,
  DeleteParams,
  SelectedParams,
  UpdateParams
} from './types'

/**
 * 修改购物车
 * @param cart_id: string
 * @param quantity: string | number
 */
export const updateCart = (params: UpdateParams) => {
  return request
    .post<CartReturnType>('/shopcart/updateCart', params)
    .then((res) => {
      message.success(`success!`)
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

/**
 * 删除购物车
 */
export const deleteCart = (params: DeleteParams) => {
  return request
    .post<CartReturnType>('/shopcart/deleteCart', params)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

/**
 * 加入购物车
 * @param products_id: number
 * @param attr_id: number[]
 * @param warehouse?: string //暂不用
 * @param quantity: number
 */
export const addCart = (params: AddCartParams) => {
  return request
    .post<CartReturnType>('/shopcart/addCart', params)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

export const buyNow = (params: AddCartParams) => {
  return request
    .post<CartReturnType>('/shopcart/buyNow', params)
    .then((res) => {
      return res?.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

/**
 * 加入购物车
 */
export type getCartParamsType = {
  lottery_coupon?: string
  show_sku_list?: (string | number)[]
  show_group?: '1' | '0'
}
export const getCart = (params?: getCartParamsType) => {
  return request
    .post<CartReturnType>('/shopcart/getCart', params)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

/**
 * 选择结算商品
 */
export const selectedCart = (params: SelectedParams) => {
  return request
    .post<CartReturnType>('/shopcart/changeSelected', params)
    .then((res) => {
      return res.data
    })
}

/**
 * @description: 使用优惠卷 /api/shopcart/changeCoupon
 * @param {string} couponCode 优惠券码
 * @param {string} clearCoupon 是否清除优惠券，1清除优惠卷
 * @param {'1'} priority_coupon 当传 1 时，表示和活动优惠冲突时，优先选择优惠券取消活动优惠，反义不传
 * @return {*}
 */
export type changeCouponParamsType = {
  couponCode: string
  clearCoupon?: string
  priority_coupon?: '1'
}
export const changeCoupon = async (params: changeCouponParamsType) => {
  //const {} = params
  const res = await request
    .post<CouponResult>('/shopcart/changeCoupon', {
      ...params,
      clearCoupon: Number(params.clearCoupon)
    })
    .catch((err) => {
      // message.open({
      //   type: 'error',
      //   content: `${err?.message}`,
      //   style: {
      //     zIndex: 9000
      //   }
      // })
      message.error(`${err?.message}`)

      throw new Error(err)
    })
  omitUrlSearch('orderId')
  return res
}

/**
 * @description: 同 changeCoupon 方法，由于 request 底层拦截了错误码，导致组件无法针对错误码做逻辑处理。由于home站多个场景在引用原 /shopcart/changeCoupon 接口。也不能直接排除原接口的拦截。只能让后端新增一个同功能，不同名的api忽略拦截
 * @param {changeCouponParamsType} params
 * @return {*}
 */
export const changeCouponV2 = async (params: changeCouponParamsType) => {
  const res = await request.post<CouponResult>('/shopcart/doChangeCoupon', {
    ...params,
    clearCoupon: Number(params.clearCoupon)
  })
  omitUrlSearch('orderId')
  return res
}

type Result = {
  orderNumber: string
  clientId: string
  approvalUrl: string
  currency: string
  redirecUrl?: string
  html?: string
  sendUrl?: string
  ordersId?: string
  list: any[]
  code: number
}

export const getPaymentReturnUrl = (obj: Type.DefineObject) => {
  return `${location.origin}${routesPath.statement}${setSearchParams(obj)}`
}
export const getOrderId = () => {
  const { orderId } = getSearchParams(window?.location.search, ['orderId'])
  return orderId
}

/**
 * 获取paypal clintId
 */
export const getPayIdV1 = (
  code: string,
  type: 'paypal' | 'card' | 'klarna'
) => {
  if (Type.isVoid(code)) {
    return Promise.resolve<Result>({} as Result)
  }

  const returnUrl = `${location.origin}${routesPath.statement}?type=${type}&code=${code}`

  const cancelUrl = `${location.origin}${routesPath.checkout}?type=cancel`

  return request
    .post<Result>('/shopcart/paymentCheckout', {
      payment_code: code,
      return_url: returnUrl,
      cancel_url: cancelUrl,
      is_express: false
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err}`)
      throw new Error(err)
    })
}

export const getAddressFromFastPay = async (id: string) => {
  return request
    .post<UserInfo>('/shopcart/getFastAddress', {
      paypalOrderId: id
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

export const getPayIdFromFastPay = async (
  code: PaymentCode = PaymentCode.paypal
) => {
  const returnUrl = getPaymentReturnUrl({ type: code, isExpress: true })

  const cancelUrl = `${location.origin}${routesPath.checkout}?type=cancel`

  return request
    .post<Result>('/shopcart/fastCheckout', {
      return_url: returnUrl,
      cancel_url: cancelUrl
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

// api/shopcart/fastCreateOrder
export const createOrderByFast = async (address) => {
  //订单创建成功就跳转
  const res = await request
    .post('/shopcart/fastCreateOrder', {
      ...address
    })
    .catch((err) => {
      notification.error({
        message: 'Failure!',
        description: err?.message,
        duration: 3
      })
      throw new Error(err)
    })

  return res?.data?.paypalOrderId as string
}

/**
 * 获取payId
 */
export const getPayId = async (
  code: PaymentCode,
  isExpress: boolean = false,
  callback?: (res) => void
) => {
  if (Type.isVoid(code)) {
    return Promise.resolve<Result>({} as Result)
  }

  const data = await createOrder(code, isExpress)
    // .then((res) => {
    //   throw new Error(
    //     JSON.stringify({
    //       isReload: true,
    //       data: {
    //         newAddress: {
    //           email: '8888888888@qq.com',
    //           streetAddress2: '22222222222222222222222222',
    //           streetAddress: 'qqqqqqqqqqqqqqqqq',
    //           fullname: '2323232fff',
    //           telephone: '12122324',
    //           city: 'usa'
    //         }
    //       }
    //     })
    //   )
    // })
    .catch((err) => {
      throw Error(err.message)
    })

  callback && callback?.(data)

  const id: string = data?.orderId

  const returnUrl = getPaymentReturnUrl({ type: code, isExpress, orderId: id })

  const cancelUrl = `${location.origin}${routesPath.checkout}?type=cancel`

  return request
    .post<Result>('/shopcart/orderCheckout', {
      isExpress,
      payment_code: code,
      order_id: id,
      return_url: returnUrl,
      cancel_url: cancelUrl
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      window.history.replaceState(null, '', window.location.pathname)
      throw new Error(err)
    })
}

export enum PaymentCode {
  paypal = 'paypal',
  payoneer_creditcard = 'payoneer_creditcard',
  oceanpay_klarna = 'oceanpay_klarna',
  oceanpay_creditcard = 'oceanpay_creditcard' // 信用卡直连
}

const subscritionCheck = (res) => {
  if (res.code === 305) {
    throw new Error(JSON.stringify({ isReload: true, data: res.data }))
  }

  // if ([530, 531, 532].includes(res.code)) {
  //   return new Promise((resolve, reject) => {
  //     showCheckoutSubscriptionTip({
  //       code: res.code,
  //       onSubmit() {
  //         resolve(res)
  //       },
  //       onRemove() {
  //         reject()
  //         window.dispatchEvent(
  //           new CustomEvent('removeCoupon', { detail: { code: '' } })
  //         )
  //       }
  //     })
  //   })
  // }
  return res
}

export const createOrder = async (
  paymentCode: PaymentCode,
  isExpress: boolean
) => {
  const orderId = getOrderId()
  if (orderId && orderId != 'undefined') {
    return { orderId }
  }
  //订单创建成功就跳转
  const res = await request
    .post('/shopcart/createOrder', {
      isExpress,
      paymentCode
    })
    .then(subscritionCheck)
    .catch((err) => {
      if (err.message.indexOf('isReload') === -1) {
        notification.error({
          message: 'Failure!',
          description: err?.message,
          duration: 3
        })
      }

      //跳主页
      throw new Error(err.message)
    })

  const params = getAllSearchParams(window.location.search)

  window.history.replaceState(
    null,
    '',
    window.location.origin +
      window.location.pathname +
      setSearchParams({ ...params, orderId: res?.data?.orderId || '' })
  )
  return res?.data
}

type PaymentLogParams = {
  ordersId: string
  request: any
  response: any
  oriResponse: any
}

// 记录信用卡返回信息
export const addPaymentlog = (params: PaymentLogParams) => {
  return request
    .post('/shopcart/addPaymentlog', {
      ...params
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err)
    })
}

/**
 * @description: 获取购物车空数据时的 best sellers
 * @param {object} params
 * @return {*}
 */
export const getBestSellers = (params?: {
  type: string
  page_size: number
}) => {
  return request
    .get('/product_flow/getProductflow', params)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}

/**
 * @description: 获取 Drawer 购物车的 coupon recommend
 * @param {object} params
 * @return {*}
 */
export const getCouponRecommend = (params?) => {
  return request
    .get('/shopcart/getCartRecommend', params)
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      message.error(`Failure! ${err?.message}`)
      throw new Error(err)
    })
}
